.custom-option {
  color: black;
  padding: 10px;
}

.custom-checkbox {
  margin-right: 5px;
}

.selectLabel {
  font-size: 16px;
  color: #1A1818;
}

.selectContainer {
  max-width: 376px;
  min-width: 300px;
  max-height: 46px;
  margin-bottom: 80px;
}
