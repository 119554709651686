.container {
  max-width: 400px;
  display: flex;
  align-items: center;
  position: relative;

  .select {
    padding: 6px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--neutral1);
    background-color: white;
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;

    option {
      color: var(--dark)
    }
  }

  .noSelected {
    color: var(--neutral2);
  }

  .icon {
    position: absolute;
    margin-right: 10px;
    right: 0;
  }
}
