.form-container {
  display: flex;
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;

  .containerItems {
    background: #F3F3F3;
    border-radius: 0px 8px 8px 8px;
    width: auto;

    .form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 50px;

      .labelsContainer{
        display: grid;
        grid-template-columns: 22.5% 25.5% 25.5% 30%;
        justify-content: left;
        margin-left: 5px;
        width: 98%;
        .optionLabel{
          margin-inline: 10px;
          color: #707070;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .endLine{
        justify-content: start;
        margin: 8px 30px 0px 0px;
        height: 1px;
        background-color: #1A1818;
        width: 94%;
      }

      .formBody{
        background-color: unset;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .optionsContainer{
          display: grid;
          grid-template-columns: 100%;
          align-items: center;
          justify-content: center;
          margin: 10px 30px -60px 30px;

          .formContributorDataContainer{
            display: flex;
            height: 90px;
            .formContributorInfos{
              margin-top: -5px;
              display: flex;
              flex-direction: column;
              justify-content: start;

              .formContributorDoc{
                font-size: 20px;
                color: #1A1818;
                font-weight: bold;
              }

              .formContributorName{
                font-size: 14px;
                color: #707070;
                width: 270px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .formOptions{
              padding-inline: 28px;
            }
          }

          .formSplitLine{
            margin: -40px 0px 0px -6px;
            height: 2px;
            background-color: #E4E4E4;
            width: 98.5%;
          }
        }
      }

      .sendFormButton{
        margin: 100px 0px 0px 0px;
        width: 299px;
        height: 35px;
        background-color: #FFFFFF;
        border-style: solid;
        border-color: #E8E8E8;
        border-radius: 4px;
        border-width: 1px;
        text-align: center;
        font-size: 14px;
        color: #E8E8E8;
        font-weight: bold;
      }

      .sendFormButtonEnabled{
        margin: 100px 0px 0px 0px;
        width: 299px;
        height: 35px;
        background-color: var(--green2);
        border-style: unset;
        border-radius: 4px;
        font-size: 14px;
        color: #1A1818;
        font-weight: bold;
        &:hover{
          cursor: pointer;
          background-color: var(--green2);
        }
      }

      .title {
        text-align: center;

        .attendantFormMessage{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          .loadingText {
            font-size: 26px;
            color: #1A1818;
            text-align: center;
          }

          .loadingTextName {
            font-weight: bold;
            font-size: 26px;
            text-align: center;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        flex-direction: row;
      }

      .enableButton {
        margin: 10px 10px 0px 0px;
        color: #1A1818;
        border: 0;
        font-size: 14px;
        padding: 5px 15px;
        font-weight: bold;
        border-radius: 5px;
        align-self: center;

        &.enabled {
          background-color: var(--green2);
        }

        &.disabled {
          background-color: var(--green2);
        }

        &:hover {
          background-color: var(--green2);
        }
      }
      .disableButton {
        margin-top: 10px;
        color: #1A1818;
        border: 0;
        font-size: 14px;
        padding: 5px 15px;
        font-weight: bold;
        border-radius: 5px;
        align-self: center;

        &.enabled {
          background-color: #B7B7B7;
        }

        &.disabled {
          background-color: #E8E8E8;
        }

        &:hover {
          background-color: #E8E8E8;
        }
      }

      .finallyAttendanceform{
        display: flex;
        flex-direction: column;
        align-items: center;

        .finallyTitle{
          margin-top: 100px;
          margin-inline: 250px;
          text-align: center;
          font-size: 26px;
          color: #1A1818;
        }
        .finallyFormButtonContainer{
          margin: 30px 0px 100px 0px;
          &>button {
            border-style: unset;
            border-radius: 4px;
            width: 168px;
            height: 38px;
            margin-inline: 10px;
            font-weight: 500;
            font-size: 15px;
            &:hover{
              cursor: pointer;
            }
          }

          .available{
            background-color: var(--green2);

            &:hover{
              background-color: var(--green1);
            }
          }

          .unavailable{
            border: 2px solid var(--dark);

            &:hover{
              background-color: var(--neutral1);
            }
          }
        }
      }
    }
  }
}

.small-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    width: 500px !important;

    .modalContent {
      max-width: 1200px !important;
      max-height: 800px !important;
      padding: 0 0 20px 0;

      .modalInput {
        margin-left: 30px;
      }

      .contentModal {
        width: 100%;

        span {
          font-size: 18px;
        }

      }

      .confirmDiv {
        display: flex;
        justify-content: end;
        margin-top: 10px;

        .enabled {
          background-color: var(--green2);

          &:hover {
            background-color: var(--green1);
            color: var(--white);
          }
        }

        .disabled {
          background-color: var(--neutral3);
          color: var(--white);
        }

        button {
          border: none;
          width: 120px;
          padding: 5px;
          border-radius: 20px;
          margin: 25px 40px 0 0;
          font-weight: bold;
        }
      }
    }

  }
}
