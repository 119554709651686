body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}
button:focus,
:focus {
  outline: none;
}

.form-control:focus {
  color: #1A1818;
  background-color: #fff;
  border-color: var(--green2);
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--green3);
}

.btn-primary {
  color: #1A1818;
  font-weight: bold;
  background-color: var(--green2);
  border-color: var(--green2);
}

.btn-disabled {
  color: #FFFFFF !important;
  font-weight: bold;
  background-color: #999999 !important;
  color: #FFFFFF !important;
  border-color: #b1b1b1 !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--green3);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--green2);
  border-color: var(--green2);
}

.btn-primary:hover {
  color: #1A1818;
  background-color: var(--green1);
  border-color: var(--green1);
}

.btn-primary:focus,
.btn-primary.focus {
  background-color: var(--green3);
  border-color: var(--green3);
  box-shadow: 0 0 0 0.2rem var(--green3);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--green2);
  border-color: var(--green2);
}

.btn-outline-primary {
  color: #1A1818;
  border-color: var(--green2);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--green3);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #1A1818;
  background-color: var(--green2);
  border-color: var(--green2);
}

.btn-outline-primary:hover {
  color: #1A1818;
  background-color: var(--green3);
  border-color: var(--green3);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  background-color: var(--green3);
  border-color: var(--green3);
  box-shadow: 0 0 0 0.2rem var(--green3);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background-color: var(--green2);
  border-color: var(--green2);
}

.fa-spin-fast {
  -webkit-animation: fa-spin .8s infinite linear;
  animation: fa-spin .8s infinite linear;
}

.breadcrumb {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #e5e5e5;
}

.breadcrumb a {
  color: #1A1818;
}

.DayPicker,
.DayPicker-Month {
  width: 100%;
}
.DayPicker * {
  outline: none;
}
.DayPicker-NavButton,
.DayPicker-wrapper,
.DayPicker-Day {
  outline: none;
}
.DayPicker-Day {
  padding: 0.3em;
  border-radius: 0.25rem;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: var(--green2);
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: var(--green2);
    color: #F0F8FF;
}
.DayPicker-NavButton {
  top: 0;
}
.DayPicker-Month {
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #FFFCF2 inset;
}

@media (max-width: 575px) {
  .btn-block-sm {
    display: block;
    width: 100%;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 718px;
  }
}
