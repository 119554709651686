.outros-debitos {
  padding: 10px 10px 10px 20px;
  display: flex;
  flex-direction: row;
  background-color: #ddd;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 10px;
}

.outros-debitos > div {
  flex: 1;
  font-weight: bold;
}

.totais {
  margin-bottom: 10px;
}

.tipo-receita {
  background-color: #FFF;
  border-radius: 0.25rem;
  padding: 15px;
  margin-bottom: 15px;
}

.tipo-receita header button,
.tipo-receita .content button,
.tipo-receita footer button {
  text-align: left;
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
}

.tipo-receita header button,
.tipo-receita footer button,
.tipo-receita .content .actions button:last-child {
  font-weight: bold;
}

.tipo-receita header {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}

.tipo-receita header button,
.tipo-receita header .total {
  line-height: 1.25rem;
  font-weight: bold;
}

.tipo-receita header button {
  flex: 1;
  outline: transparent;
}

.tipo-receita header .total {
  min-width: 1px;
  color: #65a9a5;
}

.tipo-receita .content {
  display: flex;
  flex-direction: column;
}

.tipo-receita .content .situacao {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #eee;
  padding: 5px 0;
}

.tipo-receita .content .situacao > button {
  color: #56918c;
  outline: transparent;
  font-weight: bold;
}

.tipo-receita .content .situacao ul {
  margin: 0;
}

.tipo-receita .content .situacao ul.list-unstyled {
  margin-top: 15px;
}

.tipo-receita .content .situacao ul.list-unstyled > li {
  padding: 5px 10px;
  border-radius: 0.25rem;
  background-color: #f2f2f2;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.tipo-receita .content .situacao ul.list-unstyled > li ul {
  margin-top: 15px;
}

.tipo-receita .content .situacao ul.list-unstyled > li ul li {
  padding: 0 -5px;
  background-color: #E5E5E5;
}

.tipo-receita .content .situacao ul li .actions {
  display: flex;
}

.tipo-receita .content .situacao ul li .actions .bt-select {
  flex: 1;
  color: #555;
}

.tipo-receita .content .situacao ul li .actions .bt-details {
  min-width: 1px;
  color: #56918c;
}

.tipo-receita footer {
  margin-top: 5px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #eee;
}

.tipo-receita footer .title {
  flex: 1;
  text-align: right;
  padding-right: 10px;
}

.tipo-receita footer .total {
  font-weight: bold;
  min-width: 1px;
}

.info-refis {
  margin-bottom: 0;
}

.receiptType{
  display: inline-grid;
  grid-template-columns: 240px;
  max-height: 60px;
  margin-bottom: 62px;
  margin-top: -2px;
  margin-left: 2px;
  float: left;
}

.tipoReceitaSelected{
  display: inline-grid;
  grid-template-columns: 240px;
  max-height: 60px;
  margin-top: 10px;
  margin-inline: 2px;
  float: left;
}

.itemTipoReceita{
  display: inline-grid;
  grid-template-columns: 240px;
  max-height: 60px;
  margin-top: -1px;
  margin-bottom: 59px;
  margin-inline: 2px;
  float: left;
}

.tabTipoReceita{
  background-color: #FFF;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  max-height: 120px;
  padding: 15px;
  margin-bottom: -7px;
  margin-left: 2px;
  background-color: #F3F3F3;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.5 !important;
}

.tabTipoReceitaSelected{
  background-color: #FFF;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  max-height: 120px;
  padding: 15px;
  margin-bottom: -7px;
  margin-left: 2px;
  background-color: #F3F3F3;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  opacity: 1 !important;
}

.recipTittle {
  line-height: 1.25rem;
  font-weight: bold;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  width: 210px;
}

.recipInformation{
  font-size: 14px;
}

.selectedTab{
  margin-top: 7px;
  max-width: 220px;
  height: 4px;
  background-color: var(--green2);
  margin-bottom: -6px;
}

.contentTypeVirtualDesk{
  margin-left: 2px;
  float: none;
  border-radius: 0px 8px 8px 8px;
  background-color: #ffffff;
}

.contentInformation{
  border-style: solid;
  border-width: 10px 10px 10px 2px;
  border-color: #F3F3F3;
  width: 100%;
}

.loadingScreen{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f2f2f2;
  border-radius: 1rem;
  height: 700px;
}

.loadingIcon{
  height: 350px;
  width: 350px;
  margin-top: 210px;
}

.loadingMessage{
  margin-top: -200px;
  margin-left: 5px;
}

.epgmRedirect{
  position: relative;
  margin-right: -30px;
  float: right;
  width: auto;
}

.shortcuts, .shortcutsHidden {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.shortcuts {
  flex-direction: column;
  align-items: end;
}

.shortcutsHidden {
  justify-content: end;
}

.agilisRedirect{
  position: relative;
  margin: 15px 0;
  margin-right: -30px;
  float: right;
  width: auto;
}

.epgmRedirectHidden{
  visibility: hidden;
  position: relative;
  margin-right: -30px;
  float: right;
  width: auto;
  overflow: hidden;
}

.reloadButton{
  position: relative;
  margin-right: -30px;
  margin-top: 15px;
  float: right;
  width: auto;
}

.arrowRight, .arrowLeft{
  display: none;
}

@media (max-width:768px) {
  .banner-acesso-balcao{
    margin-bottom: 25px;
    text-align: center;
    width: 100%;
  }

  .info-refis {
    margin-bottom: 1rem;
  }

  .banner-acesso-balcao button {
    width: 300px;
    font-size: 16px;
  }

  .tipoReceita{
    display: none !important;
  }

  .arrowLeft{
    display: grid !important;
    margin-top: 24px;
    margin-left: -24px;
    margin-right: 4px;
    font-size: 30px;
    color: var(--green2);
  }

  .arrowRight{
    display: grid !important;
    margin-top: 24px;
    margin-left: 5px;
    margin-right: -32px;
    font-size: 30px;
    color: var(--green2);
  }

  .tipoReceitaMobile {
    display: grid;
    grid-template-columns: 201px;
    max-height: 75px;
    margin-top: 10px;
    margin-inline: 2px;
  }

  .carouselVisibility{
    overflow: hidden;
  }

  .containerTabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-height: 80px;
  }

  .tabsContentItens {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-height: 80px;

  }

  .ContentType{
    margin-top: 2px !important;
    margin-left: 2px;
    float: none;
    width: 362px !important;
    border-radius: 0px 8px 8px 8px;
    background-color: #F3F3F3;
  }

  .contentInformation{
    margin-top: -15px;
    width: 360px !important;
  }

  .selectedTab{
    margin-top: -2px !important;
    max-width: 100px;
    height: 4px;
    background-color: var(--green2);
  }

  .recipTittle {
    width: 180px !important;
  }

  .tabTipoReceitaMobile{
    background-color: #FFF;
    border-radius: 8px 8px 0px 0px;
    max-height: 70px;
    padding: 15px;
    margin-bottom: -7px;
    background-color: #F3F3F3;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.5 !important;
  }

  .tabTipoReceitaSelected{
    background-color: #FFF;
    border-radius: 8px 8px 0px 0px;
    height: 120px;
    padding: 10px;
    margin-bottom: -7px;
    background-color: #F3F3F3;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    opacity: 1 !important;
  }
}
