.central {
  margin-top: 65px;
}

.central div {
  margin: 20px 0px;
  display: flex;
  align-items: center;
}

.central div a {
  color: #1A1818;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.central div svg {
  color: #1A1818;
  font-size: 25px;
}

.central div a:hover {
  color: #1EB1E7;
}

.central table thead {
  background: var(--green2);
}

.central table tr th,
.central table tr td {
  border-bottom: 10px solid #fff;
  vertical-align: middle !important;
}

.border-left-yellow {
  border-left: 6px solid var(--green2) !important;
}

.central table tr th div {
  min-height: 4px;
  max-height: 8px;
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
}

.central table thead .title-table {
  padding-left: 0 !important;
}

.central tbody{
  font-size: 0.8rem;
}
