.agilisLabelContainer{
  width: 256px;
}

.agilisLabelIconContainerr{
  width: 100px;
  height: 55px;
  position: relative;
  z-index: 10;
  background-color: var(--green2);
  border-radius: 28px 0px 0px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 156px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  .agilisLabelIcon{
    height: 40px;
    width: 108px;
    margin-left: 50px;
    margin-top: 10px;
  }
  ~ .agilisTextLabel {
    margin-top: -55px;
    height: 55px;
    width: 256px;
    background-color: var(--dark);
    border-radius: 28px 0px 0px 28px;
    display: flex;
    align-items: center;
    visibility: hidden;
    & > span{
      visibility: hidden;
    }
  }
  &:hover{
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
  &:hover ~ .agilisTextLabel{
    height: 55px;
    width: 270px;
    margin-left: -10px;
    transition: margin-left 200ms;
    background-color: var(--green2);
    border-radius: 28px 0px 0px 28px;
    display: flex;
    align-items: center;
    visibility: visible;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    & > span {
      visibility: visible;
      transition: visibility 210ms;
      margin-left: 24px;
      font-weight: bold;
      color: var(--white);
    }
  }
}
